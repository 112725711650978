
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { mapActions } from 'vuex'
  import { FileProcess } from '@/entities/files'

@Component({
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class BackupPaymentCell extends Cell {
  link: string = ''
  files: FileProcess[] = []

  async mounted () {
    const { item, options } = this

    if (!item?.id || !options?.name) return

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })

    this.files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: item.id } },
          {
            parameter: {
              _and: [
                { process: { id: { _eq: process[0].id } } },
                { name: { _eq: options?.name } },
              ],
            },
          },
        ],
      },
      force: true,
    })
  }

  openFiles (files) {
    files.map(file => {
      this.openLink(file.file.uri)
    })
  }
  }
